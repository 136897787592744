// ===========================================
// Icon Font
// ===========================================
[class^="icon-"]:before,
[class*=" icon-"]:before,
[class^="phase-badge-"]:before {
    font-family: "tr-icons";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: none !important;
    width: 1em;
    margin-right: .35em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 0;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    //margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */

    &:hover {
        text-decoration: none !important;
    }
}

.icon-no-margin {
    &:before {
        margin-right: 0px;
    }
}

@font-face {
    font-family: 'tr-icons';
    src: url('/fonts/tr-icons.woff2') format('woff2'),
         url('/fonts/tr-icons.woff') format('woff'),
         url('/fonts/tr-icons.ttf') format('truetype'),
         url('/fonts/tr-icons.svg#tr-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

// override for bbs icons to be centered
[class^="icon-phase-"] {
    margin: 0 auto;
    position: relative;

    &:before {
        margin: 0 auto;
    }
}

[class^="phase-badge-"] {
    display: inline;
    margin: 0 auto;
    position: relative;

    &:before {
        margin: 0 auto;
    }
}

.icon-add-notes:before { content: '\e800'; } /* '' */
.icon-arrow-down:before { content: '\e801'; } /* '' */
.icon-arrow-left:before { content: '\e802'; } /* '' */
.icon-arrow-right:before { content: '\e803'; } /* '' */
.icon-arrow-tail-down:before { content: '\e804'; } /* '' */
.icon-arrow-up:before { content: '\e805'; } /* '' */
.icon-bar-graph:before { content: '\e806'; } /* '' */
.icon-live-chat:before { content: '\e807'; } /* '' */
.icon-cadence:before { content: '\e808'; } /* '' */
.icon-calendar:before { content: '\e809'; } /* '' */
.icon-checklist:before { content: '\e80a'; } /* '' */
.icon-checkmark:before { content: '\e80b'; } /* '' */
.icon-close:before { content: '\e80c'; } /* '' */
.icon-electronic-trainer:before { content: '\e80d'; } /* '' */
.icon-error-filled:before { content: '\e80e'; } /* '' */
.icon-error:before { content: '\e80f'; } /* '' */
.icon-fast-forward:before { content: '\e810'; } /* '' */
.icon-filter:before { content: '\e811'; } /* '' */
.icon-heart-rate:before { content: '\e812'; } /* '' */
.icon-information:before { content: '\e813'; } /* '' */
.icon-line-graph:before { content: '\e814'; } /* '' */
.icon-locked:before { content: '\e815'; } /* '' */
.icon-notes:before { content: '\e816'; } /* '' */
.icon-notification:before { content: '\e817'; } /* '' */
.icon-paired:before { content: '\e818'; } /* '' */
.icon-pause:before { content: '\e819'; } /* '' */
.icon-personal-record:before { content: '\e81a'; } /* '' */
.icon-pin:before { content: '\e81b'; } /* '' */
.icon-play:before { content: '\e81c'; } /* '' */
.icon-power-meter:before { content: '\e81d'; } /* '' */
.icon-refresh:before { content: '\e81e'; } /* '' */
.icon-rewind:before { content: '\e81f'; } /* '' */
.icon-search:before { content: '\e820'; } /* '' */
.icon-settings:before { content: '\e821'; } /* '' */
.icon-sound-high:before { content: '\e822'; } /* '' */
.icon-sound-low:before { content: '\e823'; } /* '' */
.icon-sound-mid:before { content: '\e824'; } /* '' */
.icon-sound-off:before { content: '\e825'; } /* '' */
.icon-speed-cadence:before { content: '\e826'; } /* '' */
.icon-speed:before { content: '\e827'; } /* '' */
.icon-star-filled:before { content: '\e828'; } /* '' */
.icon-star:before { content: '\e829'; } /* '' */
.icon-stop:before { content: '\e82a'; } /* '' */
.icon-support-filled:before { content: '\e82b'; } /* '' */
.icon-support:before { content: '\e82c'; } /* '' */
.icon-team:before { content: '\e82d'; } /* '' */
.icon-thumbs-up:before { content: '\e82e'; } /* '' */
.icon-timer:before { content: '\e82f'; } /* '' */
.icon-toggles:before { content: '\e830'; } /* '' */
.icon-unlocked:before { content: '\e831'; } /* '' */
.icon-unpaired:before { content: '\e832'; } /* '' */
.icon-user:before { content: '\e833'; } /* '' */
.icon-video:before { content: '\e834'; } /* '' */
.icon-virtualpower:before { content: '\e835'; } /* '' */
.icon-workout-creator:before { content: '\e836'; } /* '' */
.icon-workout-instructions:before { content: '\e837'; } /* '' */
.icon-arrow-tail-left:before { content: '\e838'; } /* '' */
.icon-arrow-tail-right:before { content: '\e839'; } /* '' */
.icon-arrow-tail-up:before { content: '\e83a'; } /* '' */
.icon-podcast:before { content: '\e83b'; } /* '' */
.icon-live-data:before { content: '\e83c'; } /* '' */
.icon-email-series:before { content: '\e83d'; } /* '' */
.icon-cross-platform:before { content: '\e83e'; } /* '' */
.icon-blog:before { content: '\e83f'; } /* '' */
.icon-signal:before { content: '\e840'; } /* '' */
.icon-hamburger:before { content: '\e841'; } /* '' */
.icon-android:before { content: '\e842'; } /* '' */
.icon-apple:before { content: '\e843'; } /* '' */
.icon-windows:before { content: '\e844'; } /* '' */
.icon-ant:before { content: '\e845'; } /* '' */
.icon-bluetooth:before { content: '\e846'; } /* '' */
.icon-phase-one:before { content: '\e847'; } /* '' */
.icon-phase-two:before { content: '\e848'; } /* '' */
.icon-phase-three:before { content: '\e849'; } /* '' */
.icon-bicycle-indoor:before { content: '\e84a'; } /* '' */
.icon-bicycle:before { content: '\e84b'; } /* '' */
.icon-download:before { content: '\e84c'; } /* '' */
.icon-delete:before { content: '\e84d'; } /* '' */
.icon-share:before { content: '\e84e'; } /* '' */
.icon-edit:before { content: '\e84f'; } /* '' */
.icon-minus:before { content: '\e850'; } /* '' */
.icon-personal-record-filled:before { content: '\e851'; } /* '' */
.icon-plus:before { content: '\e852'; } /* '' */
.icon-pin-filled:before { content: '\e853'; } /* '' */
.icon-season-pr:before { content: '\e854'; } /* '' */
.icon-move:before { content: '\e855'; } /* '' */
.icon-tr:before { content: '\e859'; } /* '' */
.icon-copy:before { content: '\e85c'; } /* '' */
.icon-more:before { content: '\e85e'; } /* '' */
.icon-window-expand:before { content: '\e85f'; } /* '' */
.icon-window-reduce:before { content: '\e860'; } /* '' */
.icon-battery-25:before { content: '\e865'; } /* '' */
.icon-battery-50:before { content: '\e866'; } /* '' */
.icon-battery-75:before { content: '\e867'; } /* '' */
.icon-battery-100:before { content: '\e868'; } /* '' */
.icon-pull-thick:before { content: '\e86a'; } /* '' */
.icon-pull:before { content: '\e86b'; } /* '' */
.icon-push-thick:before { content: '\e86c'; } /* '' */
.icon-push:before { content: '\e86d'; } /* '' */
.icon-close-filled:before { content: '\e86e'; } /* '' */
.icon-web-link:before { content: '\e8ae'; } /* '' */
.icon-run:before { content: '\e8af'; } /* '' */
.icon-cross-training:before { content: '\e8b0'; } /* '' */
.icon-swim:before { content: '\e8b1'; } /* '' */
.icon-checkmark-thick:before { content: '\e8b4'; } /* '' */
.icon-checkmark-circle-filled:before { content: '\e8b5'; } /* '' */
.icon-arrow-tail-thick-down:before { content: '\e8c1'; } /* '' */
.icon-arrow-tail-thick-left:before { content: '\e8c2'; } /* '' */
.icon-arrow-tail-thick-right:before { content: '\e8c3'; } /* '' */
.icon-arrow-tail-thick-up:before { content: '\e8c4'; } /* '' */
.icon-tr-new:before { content: '\e8cf'; } /* '' */
