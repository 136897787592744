$gutter-width: $spacing-32;
$outer-margin: $spacing-48;
$half-gutter-width: #{$gutter-width} * 0.5;
$gutter-compensation: #{$half-gutter-width} * -1;
$gutters: (
    none: 0px,
    2: $spacing-2,
    4: $spacing-4,
    8: $spacing-8,
    12: $spacing-12,
    16: $spacing-16,
    20: $spacing-20,
    24: $spacing-24,
    32: $spacing-32,
    40: $spacing-40,
    48: $spacing-48,
);

.container-fluid, .container {
    margin-right: auto;
    margin-left: auto;
    max-width: 1110px;
    width: 100%;
}

.container-fluid {
    padding-right: $outer-margin;
    padding-left: $outer-margin;
}

.row {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: calc(#{$gutter-compensation});
    margin-left: calc(#{$gutter-compensation});
}

.col {
    flex: 1;
    max-width: 100%;
}

@each $size in $sizes {
    @include bp(nth($size, 1)) {
        .#{nth($size, 2)}__col {
            flex: 1;
            max-width: 100%;
        }
    }
}

@for $i from 1 through 12 {
    .col-#{$i} {
        box-sizing: border-box;
        flex: 0 0 auto;
        flex-basis: 1/12*$i*100%;
        max-width: 1/12*$i*100%;
        padding-right: calc(#{$half-gutter-width});
        padding-left: calc(#{$half-gutter-width});

        &--offset {
            margin-left: 1/12*$i*100%;
        }
    }
}

@each $size in $sizes {
    @include bp(nth($size, 1)) {
        @for $i from 1 through 12 {
            .#{nth($size, 2)}__col-#{$i} {
                box-sizing: border-box;
                flex: 0 0 auto;
                flex-basis: 1/12*$i*100%;
                max-width: 1/12*$i*100%;
                padding-right: calc(#{$half-gutter-width});
                padding-left: calc(#{$half-gutter-width});

                &--offset {
                    margin-left: 1/12*$i*100%;
                }
            }
        }
    }
}

.row.gutter {
    @each $gutter in $gutters {
        $half-gutter-width: calc(#{nth($gutter,2)} * .5);
        $gutter-compensation: calc(#{$half-gutter-width} * -1);

        &--#{nth($gutter, 1)} {
            margin-right: $gutter-compensation;
            margin-left: $gutter-compensation;

            @for $i from 1 through 12 {
                & > .col-#{$i} {
                    padding-right: $half-gutter-width;
                    padding-left: $half-gutter-width;
                }
            }

            @each $size in $sizes {
                @include bp(nth($size, 1)) {
                    @for $i from 1 through 12 {
                        & > .#{nth($size, 2)}__col-#{$i} {
                            padding-right: $half-gutter-width;
                            padding-left: $half-gutter-width;
                        }
                    }
                }
            }
        }
    }
}
