// Utility classes for horizontal & vertical spacing
//------------------------------

$spacing: (
    none: 0,
    2: $spacing-2,
    4: $spacing-4,
    8: $spacing-8,
    12: $spacing-12,
    16: $spacing-16,
    20: $spacing-20,
    24: $spacing-24,
    32: $spacing-32,
    40: $spacing-40,
    48: $spacing-48,
    60: $spacing-60,
    auto: auto,
);

$types: margin, padding;
$directions: horizontal, vertical, top, right, bottom, left;

@each $type in $types {
    @each $space in $spacing {
        .#{$type}--#{nth($space,1)} {
            #{$type}: #{nth($space,2)} !important;
        }
    }
}

@each $type in $types {
    @each $direction in $directions {
        @each $space in $spacing {
            .#{$type}-#{$direction}--#{nth($space,1)} {
                @if ($direction == horizontal) {
                    #{$type}-left: #{nth($space,2)} !important;
                    #{$type}-right: #{nth($space,2)} !important;
                }

                @else if ($direction == vertical) {
                    #{$type}-top: #{nth($space,2)} !important;
                    #{$type}-bottom: #{nth($space,2)} !important;
                }

                @else {
                    #{$type}-#{$direction}: #{nth($space,2)} !important;
                }
            }
        }
    }
}

@each $type in $types {
    @each $direction in $directions {
        @each $space in $spacing {
            @each $size in $sizes {
                @include bp(nth($size, 1)) {
                    .#{nth($size, 2)}__#{$type}-#{$direction}--#{nth($space,1)} {
                        @if ($direction == horizontal) {
                            #{$type}-left: #{nth($space,2)} !important;
                            #{$type}-right: #{nth($space,2)} !important;
                        }

                        @else if ($direction == vertical) {
                            #{$type}-top: #{nth($space,2)} !important;
                            #{$type}-bottom: #{nth($space,2)} !important;
                        }

                        @else {
                            #{$type}-#{$direction}: #{nth($space,2)} !important;
                        }
                    }
                }
            }
        }
    }
}
