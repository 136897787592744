// SCSS Variables
//------------------------------
// 1.0 - Screen Sizes
// 2.0 - Colors
// 3.0 - Typography
// 4.0 - Spacing
// 5.0 - Animation
//------------------------------


// 1.0 - Screen Sizes
//------------------------------
$sizes: (
    tablet: sm,
    desktop: md,
    desktop-large: lg,
    desktop-extra-large: xl,
);

// 2.0 - Colors
//------------------------------
$color__black              : #151515;
$color__grey--1            : #1d1d1d;
$color__grey--2            : #252525;
$color__grey--3            : #2e2e2e;
$color__grey--4            : #373737;
$color__grey--5            : #3f3f3f;
$color__grey--6            : #505050;
$color__grey--7            : #6a6a6a;
$color__grey--8            : #838383;
$color__grey--9            : #9d9d9d;
$color__grey--10           : #b6b6b6;
$color__grey--11           : #d0d0d0;
$color__grey--12           : #e0e0e0;
$color__grey--13           : #e9e9e9;
$color__grey--14           : #f1f1f1;
$color__grey--15           : #fafafa;
$color__white              : #ffffff;

$color__red--1             : #801810;
$color__red--2             : #da291c;
$color__red--3             : #e53f33;
$color__red                : $color__red--2;
$color__red--hover         : $color__red--3;

$color__green--1           : #377c1c;
$color__green--2           : #439f1f;
$color__green--3           : #5bb836;
$color__green--4           : #7acd59;
$color__green--5           : #bae1a2;
$color__green              : $color__green--2;
$color__green--hover       : $color__green--3;

$color__blue--1            : #004062;
$color__blue--2            : #1271b4;
$color__blue--3            : #1482c8;
$color__blue--4            : #1795dc;
$color__blue--5            : #19a2eb;
$color__blue--6            : #51bef1;
$color__blue               : $color__blue--3;
$color__blue--hover        : $color__blue--4;

$color__yellow--1          : #fbb000;
$color__yellow--2          : #fac800;
$color__yellow--3          : #fad342;
$color__yellow--4          : #fdebaf;
$color__yellow             : $color__yellow--2;

$color__brown--1           : #bd7019;
$color__brown--2           : #e28a27;
$color__brown              : $color__brown--1;

// Links
$color__link               : $color__blue--3;
$color__link--hover        : $color__blue--4;

// Activities
$color__activity--complete : $color__green--3;
$color__activity--other    : $color__green--5;
$color__activity--skipped  : $color__grey--4;
$plan-color-remaining      : white;

// Rider Types
$color__road-racer         : $color__yellow--1;
$color__road               : $color__road-racer;
$color__triathlete         : $color__blue--2;
$color__off-road           : $color__brown--1;
$color__enthusiast         : $color__green--1;

$color__road-racer--hover  : $color__yellow--2;
$color__road--hover        : $color__road-racer--hover;
$color__triathlete--hover  : $color__blue--3;
$color__off-road--hover    : $color__brown--2;
$color__enthusiast--hover  : $color__green--2;

// PRs
$color__pr--1              : $color__blue--1;
$color__pr--2              : $color__blue--2;
$color__pr--3              : $color__blue--6;

// TSS
$color__tss--indoor        : $color__green--3;
$color__tss--outdoor       : $color__green--5;
$color__tss--planned-tr    : #bebebe;
$color__tss--planned-other : #dbdbdb;
$color__tss--indoor--hover : $color__yellow--3;
$color__tss--outdoor--hover: $color__yellow--4;
$color__tss--ftp           : #777;
$color__tss--average       : #a9a9a9;

// Vendors
$color__strava             : #f74d00;
$color__garmin             : #0071c3;
$color__training-peaks     : #2b5882;
$color__dropbox            : #0061ff;

// Misc
$color__primary            : $color__red;
$color__success            : $color__green;
$color__error              : $color__red--1;
$color__warning            : $color__error;
$color__danger             : $color__error;
$color__favorite           : $color__yellow--2;
$color__temp               : #da1ccd;


// 3.0 - Typography
//------------------------------
$font-family--bebas        : "bebas-neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family--sans-serif   : "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family--serif        : "adobe-garamond-pro", Georgia, serif;

$font-size--8              : 0.8rem;
$font-size--10             : 1.0rem;
$font-size--12             : 1.2rem;
$font-size--14             : 1.4rem;
$font-size--16             : 1.6rem;
$font-size--18             : 1.8rem;
$font-size--20             : 2.0rem;
$font-size--24             : 2.4rem;
$font-size--27             : 2.7rem;
$font-size--34             : 3.4rem;
$font-size--40             : 4.0rem;


// 4.0 - Spacing
//------------------------------
$spacing-2                 : 2px;
$spacing-4                 : 4px;
$spacing-8                 : 8px;
$spacing-12                : 12px;
$spacing-16                : 16px;
$spacing-20                : 20px;
$spacing-24                : 24px;
$spacing-32                : 32px;
$spacing-40                : 40px;
$spacing-48                : 48px;
$spacing-60                : 60px;


// 5.0 - Animation
//------------------------------
$transition--default       : 150ms ease;
$transition--enter         : 150ms ease-out;
$transition--exit          : 150ms ease-in;
$transition--spring        : 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);


// 6.0 - Miscellaneous
//------------------------------
$windows-titlebar--height  : 32px;
