@keyframes fadeIn {
    from { opacity: 0; visibility: hidden; }
    to { opacity: 1; visibility: visible; }
}

.animation__fade--in {
    animation: fadeIn 300ms ease;
}

@keyframes fadeOut {
    from { opacity: 1; visibility: visible; }
    to { opacity: 0; visibility: hidden; }
}

.animation__fade--out {
    animation: fadeOut 300ms ease forwards;
}

// CSSTransition Classes
.fade {
    &-enter {
        opacity: 0;

        &-active,
        &-done {
            opacity: 1;
        }
    }

    &-exit {
        opacity: 1;

        &-active,
        &-done {
            opacity: 0;
        }
    }
}
