// Utilities Styles
//------------------------------
@import "colors";
@import "spacing";
@import "animation";
//------------------------------
// 1.0 - Display
// 2.0 - Position
// 3.0 - Flexbox
// 4.0 - Float
// 5.0 - Typography
// 6.0 - Box Shadow
// 7.0 - Overflow
// 8.0 - Tools
//------------------------------

// Utilities Format
//------------------------------
//  [CSS property]    : (
//      [modifier]    : [style],
//  ),
//
//------------------------------
//  Result:
//  .[CSS property]--[modifier] {
//      [CSS property]: [style];
//  }
$utilities            : (
    // 1.0 - Display
    //------------------------------
    display           : (
        none          : none,
        inline        : inline,
        inline-block  : inline-block,
        block         : block,
        flex          : flex,
        inline-flex   : inline-flex,
    ),

    // 2.0 - Position
    //------------------------------
    position          : (
        static        : static,
        relative      : relative,
        fixed         : fixed,
        absolute      : absolute,
        sticky        : sticky,
    ),

    // 3.0 - Flexbox
    //------------------------------
    flex-direction    : (
        row           : row,
        column        : column,
        row-reverse   : row-reverse,
        column-reverse: column-reverse,
    ),
    flex-wrap         : (
        wrap          : wrap,
        nowrap        : nowrap,
        wrap-reverse  : wrap-reverse,
    ),
    flex-grow         : (
        0             : 0,
        1             : 1,
    ),
    flex-shrink       : (
        0             : 0,
        1             : 1,
    ),
    flex              : (
        none          : none,
        1             : 1,
        auto          : 0 0 auto,
    ),
    justify-content   : (
        flex-start    : flex-start,
        flex-end      : flex-end,
        center        : center,
        space-between : space-between,
        space-around  : space-around,
        baseline      : baseline,
        stretch       : stretch,
    ),
    justify-self      : (
        start         : start,
        end           : end,
        center        : center,
        stretch       : stretch,
    ),
    align-items       : (
        flex-start    : flex-start,
        flex-end      : flex-end,
        center        : center,
        baseline      : baseline,
        stretch       : stretch,
    ),
    align-content     : (
        flex-start    : flex-start,
        flex-end      : flex-end,
        center        : center,
        space-between : space-between,
        space-around  : space-around,
        stretch       : stretch,
    ),
    align-self        : (
        auto          : auto,
        flex-start    : flex-start,
        flex-end      : flex-end,
        center        : center,
        baseline      : baseline,
        stretch       : stretch,
    ),


    // 4.0 - Float
    //------------------------------
    float             : (
        left          : left,
        right         : right,
        none          : none,
    ),

    // 5.0 - Typography
    //------------------------------
    font-family       : (
        bebas         : quote($font-family--bebas),
        sans-serif    : quote($font-family--sans-serif),
        serif         : quote($font-family--serif),
    ),
    font-size         : (
        8             : $font-size--8,
        10            : $font-size--10,
        12            : $font-size--12,
        14            : $font-size--14,
        16            : $font-size--16,
        18            : $font-size--18,
        20            : $font-size--20,
        24            : $font-size--24,
        27            : $font-size--27,
        34            : $font-size--34,
        40            : $font-size--40,
    ),
    font-weight       : (
        thin          : 100,
        medium        : 200,
        light         : 300,
        normal        : normal,
        semi-bold     : 600,
        bold          : bold,
        extra-bold    : 800,
        black         : 900,
    ),
    font-style        : (
        italic        : italic,
    ),
    text-align        : (
        left          : left,
        center        : center,
        right         : right,
        justify       : justify,
    ),
    line-height       : (
        1             : 1,
    ),
    white-space       : (
        nowrap       : nowrap,
    ),

    // 6.0 - Box Shadow
    //------------------------------
    box-shadow        : (
        default       : '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
        none          : none,
        small         : '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)',
        large         : '0 1rem 3rem rgba(0, 0, 0, 0.175)',
    ),

    // 7.0 - Overflow
    //------------------------------
    overflow          : (
        auto          : auto,
        hidden        : hidden,
        visible       : visible,
    ),

    // 8.0 - Tools
    //------------------------------
    cursor            : (
        pointer       : pointer,
        default       : default,
    ),
    pointer-events    : (
        none          : none,
    ),
    user-select       : (
        none          : none,
    ),
    width             : (
        1             : "1px",
        100           : "100%",
        100vw         : "100vw"
    ),
    height            : (
        1             : "1px",
        100           : "100%",
        100vh         : "100vh"
    ),
    transition        : (
        default       : "all 200ms ease-out"
    ),
);

@each $utility in $utilities {
    @each $style in nth($utility,2) {
        .#{nth($utility,1)}--#{nth($style,1)} {
            #{nth($utility,1)}: #{nth($style,2)} !important;
        }
    }
}

@each $utility in $utilities {
    @each $style in nth($utility,2) {
        @each $size in $sizes {
            @include bp(nth($size, 1)) {
                .#{nth($size, 2)}__#{nth($utility,1)}--#{nth($style,1)} {
                    #{nth($utility,1)}: #{nth($style,2)} !important;
                }
            }
        }
    }
}

// 8.0 - Tools
//------------------------------
.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.hr {
    display: block;
    width: 100%;
    height: 1px;
    min-height: 1px;
    background-color: $color__grey--11;
}

.tabular-nums {
    font-variant-numeric: tabular-nums;
    -moz-font-feature-settings: "tnum";
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
}
