@import "../../../assets/styles/lib/variables";

.login {
    &__label, &__input, &__submit {
        transition: all 200ms ease-out;
    }

    &__label {
        position: absolute;
        top: 12px; left: 16px;
        color: $color__grey--9;
        font-size: $font-size--10;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.04em;
    }

    &__input {
        width: 248px;
        padding: 28px $spacing-16 10px $spacing-16;
        color: $color__grey--4;
        font-size: $font-size--16;
        border: 1px solid $color__grey--11;
        border-radius: $spacing-2;
        background-color: $color__white;
        outline: none;
    }

    &__submit {
        width: 58px;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color__white;
        font-size: $font-size--24;
        background-color: $color__red;
        border-radius: $spacing-2;
        margin-left: $spacing-8;
        cursor: pointer;

        &:hover {
            background-color: $color__red--3;
        }
    }

    &__error {
        position: absolute;
        top: 0; left: $spacing-12;
        padding: $spacing-8;
        background-color: $color__white;
        border-radius: 2px;
        display: flex;
        align-items: center;
        transform: translate3d(0, -90%, 0);
        color: $color__grey--6;
        font-size: $font-size--12;
        box-shadow: 0px 2px 12px rgba(0,0,0,.15);
        pointer-events: none;

        &:before {
            content: "";
            width: 10px;
            height: 10px;
            background-color: $color__white;
            position: absolute;
            bottom: -5px; left: 14px;
            transform: rotate(45deg);
        }

        .icon-error {
            color: $color__error;
            margin-right: $spacing-8;
        }
    }
}

.error {
    .login__label { color: $color__error; }
    .login__input { border-color: $color__error; }
}
