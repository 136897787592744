// SCSS Mixins
//------------------------------
// 1.0 - Media Queries
// 2.0 - Retina
//------------------------------


// 1.0 - Media Queries
// --------------------------------------------------
$mediaQueries: (
    mobile-tiny        : 'only screen and (max-width: 370px)',
    mobile-landscape   : 'only screen and (min-width: 480px)',
    mobile-only        : 'only screen and (max-width: 767px)',
    tablet             : 'only screen and (min-width: 768px)',
    tablet-large       : 'only screen and (min-width: 958px)',
    desktop            : 'only screen and (min-width: 992px)',
    desktop-large      : 'only screen and (min-width: 1200px)',
    desktop-extra-large: 'only screen and (min-width: 1400px)',
);

@function media-query($name) {
  @return unquote(map-get($mediaQueries, $name));
}

@mixin bp($name) {
  @media #{media-query($name)} {
    @content;
  }
}


// 2.0 - Retina
//------------------------------
$highdpi: "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)";

@function at2x_path($path) {
    $images: png, jpeg, jpg, gif, svg;

    @each $image in $images {
        $i: str-index($path, '.'+#{$image});

        @if ($i) {
            @return str-insert($path, '@2x', $i);
        }
    }

    @return false;
}

@mixin at2x($path, $w: auto, $h: auto){
    $path: to_lower_case($path);
    background-image: url($path);
    background-repeat: no-repeat;

    @if (at2x_path($path) != false) {
        @media #{$highdpi} {
            background-image: url(at2x_path($path));
            background-size: $w $h;
        }
    }
}
