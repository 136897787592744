// Utility classes for colors
//------------------------------
// 1.0 - Brand
// 2.0 - Links
// 3.0 - Activities (Complete, Outdoor, Skipped)
// 4.0 - Rider Types
//------------------------------

$colors               : (
    // 1.0 - Brand
    //------------------------------
    primary           : $color__primary,
    success           : $color__success,
    warning           : $color__warning,
    danger            : $color__danger,
    temp              : $color__temp,
    black             : $color__black,
    grey--1           : $color__grey--1,
    grey--2           : $color__grey--2,
    grey--3           : $color__grey--3,
    grey--4           : $color__grey--4,
    grey--5           : $color__grey--5,
    grey--6           : $color__grey--6,
    grey--7           : $color__grey--7,
    grey--8           : $color__grey--8,
    grey--9           : $color__grey--9,
    grey--10          : $color__grey--10,
    grey--11          : $color__grey--11,
    grey--12          : $color__grey--12,
    grey--13          : $color__grey--13,
    grey--14          : $color__grey--14,
    grey--15          : $color__grey--15,
    red               : $color__red,
    red--1            : $color__red--1,
    yellow--1         : $color__yellow--1,
    yellow--2         : $color__yellow--2,
    yellow--3         : $color__yellow--3,
    yellow--4         : $color__yellow--4,
    yellow            : $color__yellow--2,
    brown--1          : $color__brown--1,
    brown--2          : $color__brown--2,
    brown             : $color__brown--1,
    blue              : $color__blue,
    green             : $color__green,
    error             : $color__error,
    favorite          : $color__favorite,
    white             : $color__white,

    // 2.0 - Links
    //------------------------------
    link              : $color__link,
    link--hover       : $color__link--hover,

    // 3.0 - Activities
    //------------------------------
    activity--complete: $color__activity--complete,
    activity--other   : $color__activity--other,
    activity--skipped : $color__activity--skipped,

    // 4.0 - Rider Types
    //------------------------------
    road-racer        : $color__road-racer,
    road-racer--hover : $color__road-racer--hover,
    road              : $color__road-racer,
    road--hover       : $color__road-racer--hover,
    triathlete        : $color__triathlete,
    triathlete--hover : $color__triathlete--hover,
    off-road          : $color__off-road,
    off-road--hover   : $color__off-road--hover,
    enthusiast        : $color__enthusiast,
    enthusiast--hover : $color__enthusiast--hover,
);

@each $color in $colors {
    .color__#{nth($color,1)} {
        color: #{nth($color,2)} !important;
    }

    .background-color__#{nth($color,1)} {
        background-color: #{nth($color,2)} !important;
    }

    .border-color__#{nth($color,1)} {
        border-color: #{nth($color,2)} !important;
    }

    .fill__#{nth($color,1)} {
        fill: #{nth($color,2)} !important;
    }
}
