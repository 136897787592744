* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

:root {
    font-size: 62.5%;


    body {
        margin: 0;
		height: 100%;
        font-size: $font-size--16;
		font-family: $font-family--sans-serif;
		overflow: hidden;

        p, h1, h2, h3, h4, h5, h6 {
            margin: 0;
			font-weight: normal;
        }
    }
}

body,
html,
#root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

hr {
    border-style: solid;
}

a.link--underline {
	position: relative;
	color: $color__grey--7;
	text-decoration: none;

	&:before {
		content: "";
		position: absolute;
		left: 0px;
		bottom: -1px;
		width: 100%;
		height: 1px;
		background: $color__primary;
	}

	&:hover {
		color: $color__grey--5;
	}
}
